import React from "react"

// Libraries
import styled, { keyframes } from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import { ButtonLink } from "components/button/"

// Icons
// import IconBlobApple from "assets/icons/home/icon-hero-blob--apple.inline.svg"
// import IconBlobPink from "assets/icons/home/icon-hero-blob--pink.inline.svg"

const Transform = keyframes`
  0%,
  20% { border-radius: 37% 63% 51% 49% / 37% 65% 35% 63%; } 
  40% { border-radius: 36% 64% 64% 36% / 64% 48% 52% 36%; } 
  60% { border-radius: 37% 63% 51% 49% / 30% 30% 70% 70%; } 
  80% { border-radius: 40% 60% 42% 58% / 41% 51% 49% 59%; }
  100% { border-radius: 33% 67% 70% 30% / 30% 30% 70% 70%; } 
`

const TransformPink = keyframes`
  0%,
  20% { border-radius: 50% 44% 70% 30% / 52% 44% 65% 40%; } 
  40% { border-radius: 30% 53% 64% 30% / 41% 48% 56% 41%; } 
  60% { border-radius: 40% 45% 70% 30% / 30% 30% 70% 70%; } 
  80% { border-radius: 40% 60% 42% 58% / 41% 51% 49% 59%; }
  100% { border-radius: 33% 67% 70% 30% / 30% 30% 70% 70%; } 
`

const MovementApple = keyframes`
  0% { transform: translate(-10%, -10%) rotateY(25deg) scale(1.2); }
  25% { transform: translate(20%, -5%) rotateY(10deg) scale(0.7); }
  50% { transform: translate(50%, 20%) rotateY(10deg) scale(1); }
  100% { transform: none; }
`

const MovementPink = keyframes`
  % { transform: translate(-10%, -10%) rotateY(-25deg) scale(0.9); }
  25% { transform: translate(20%, -5%) rotateY(-30deg) scale(0.7); }
  50% { transform: translate(50%, 20%) rotateY(-7deg) scale(1.2); }
  100% { transform: none; }
`

const StyledHero = styled.section`
  max-height: -webkit-fill-available;
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;

  .hero__content {
    position: relative;
    z-index: 11;
  }

  .hero__blobs {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;

    .blob {
      border-radius: 30% 50% 20% 40%;
      animation: transform 20s ease-in-out infinite both alternate,
        movement_one 40s ease-in-out infinite both;
      position: absolute;
      right: 0;

      &--apple {
        width: 200px;
        height: 166px;
        top: 5%;
        animation: ${Transform} 30s ease-in-out infinite both alternate,
          ${MovementApple} 60s ease-in-out infinite both;

        ${breakpoint.medium`
          width: 35vw;
          height: 33vw;
        `}
      }

      &--pink {
        width: 300px;
        height: 250px;
        bottom: 10%;
        right: 10%;
        animation: ${TransformPink} 30s ease-in-out infinite both alternate,
          ${MovementPink} 60s ease-in-out infinite both;

        ${breakpoint.medium`
          width: 45vw;
          height: 45vw;
        `}
      }
    }
  }
`

const Hero = () => (
  <StyledHero className="bg--pink__light d-flex align-items-center">
    <Container>
      <div className="row">
        <div className="hero__content col-12 col-md-7">
          <h1 className="mb-3">
            Breaking the web, <br />
            one pixel at the time.
          </h1>

          <div className="row mb-3">
            <div className="col-8">
              <p>
                Hi! I’m Tom, a front-end developer based in Buenos Aires,
                Argentina.
              </p>
            </div>
          </div>

          <ButtonLink to="/#skills">See what I can do</ButtonLink>
        </div>

        <div className="hero__blobs">
          <div
            className="blob blob--apple bg--apple"
            style={{
              left: `${Math.floor(Math.random() * (50 - 10 + 1)) + 10}%`,
            }}
          />

          <div
            className="blob blob--pink bg--pink"
            style={{
              left: `${Math.floor(Math.random() * (50 - 10 + 1)) + 10}%`,
            }}
          />
          {/* <IconBlobApple className="blob blob--apple" />
          <IconBlobPink className="blob blob--pink" /> */}
        </div>
      </div>
    </Container>
  </StyledHero>
)

export default Hero
