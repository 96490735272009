import React from "react"

// Libraries
import styled from "styled-components"
// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container"
import Carousel from "components/carousel/"

// Icons
import IconGatsby from "assets/icons/home/icon-gatsby.inline.svg"
import IconNext from "assets/icons/home/icon-next.inline.svg"
import IconStyledComponents from "assets/icons/home/icon-styled-components.inline.svg"
import IconGraphQL from "assets/icons/home/icon-graphql.inline.svg"
import IconShopify from "assets/icons/home/icon-shopify.inline.svg"
import IconContentful from "assets/icons/home/icon-contentful.inline.svg"
import IconTailwind from "assets/icons/home/icon-tailwind.inline.svg"

const StyledSkiils = styled.section`
  width: 100%;
  padding: 60px 0;

  ${breakpoint.medium`
    padding: 108px 0;
  `}

  .skills__carousel {
    .slick-track {
      display: flex;
    }

    .slick-slide {
      height: 60px;
      // padding-right: 60px;

      > div {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 12px;
      }

      svg {
        width: auto;
        max-height: 60px;
      }
    }
  }
`

const Skills = () => {
  const techStacks = [
    <IconGatsby />,
    <IconNext />,
    <IconStyledComponents />,
    <IconGraphQL />,
    <IconShopify />,
    <IconContentful />,
    <IconTailwind />,
  ]

  return (
    <StyledSkiils id="skills">
      <Container>
        <div className="skills__carousel text-center">
          <p className="mb-3 paragraph--small font-weight--600 opacity--50">
            STUFF I LOVE WORKING WITH
          </p>

          <Carousel
            settings={{
              dots: false,
              arrows: false,
              autoplay: true,
              autoplaySpeed: 3000,
              speed: 1000,
              slidesToShow: 4,
              responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 3,
                  },
                },
                {
                  breakpoint: 460,
                  settings: {
                    slidesToShow: 2,
                  },
                },
              ],
            }}
          >
            {techStacks.map((stack) => (
              <div className="stack d-flex align-items-center justify-content-center">
                {stack}
              </div>
            ))}
          </Carousel>
        </div>
      </Container>
    </StyledSkiils>
  )
}

export default Skills
