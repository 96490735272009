import React from "react"

// Libraries
import PropTypes from "prop-types"
import styled from "styled-components"
import { transparentize } from "polished"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Utils
import { colors } from "utils/variables/"
import breakpoint from "utils/breakpoints/"

// Components
import { ButtonExternalLink } from "components/button"

const StyledProjectCard = styled.div`
  width: 100%;
  background-color: ${(props) => transparentize(0.9, props.theme)};
  border-radius: 40px;

  ${breakpoint.medium`
    padding-top: 60px;
  `}

  .project__details {
    padding: 24px 24px 40px 24px;

    h2 {
      color: ${(props) => props.theme};
    }

    p {
      max-width: 288px;
    }

    a {
      color: ${(props) => props.theme};
      border-color: ${(props) => props.theme};

      &:hover {
        background-color: ${(props) => props.theme};
        color: ${colors.white};
      }
    }
  }

  .project__image {
    // width: 100%;
    aspect-ratio: 1;
  }
`

const ProjectCard = ({
  title,
  description,
  url,
  image,
  theme,
  imageOnRight,
}) => (
  <StyledProjectCard theme={theme}>
    <div className="row justify-content-center">
      <div className="col-12 col-md-10">
        <div className="row align-items-center justify-content-between">
          <div
            className={
              imageOnRight ? "col-12 col-sm-7 order-sm-2" : "col-12 col-sm-7"
            }
          >
            <GatsbyImage
              image={getImage(image)}
              className="project__image"
              alt=""
            />
          </div>
          <div className="col-12 col-sm-5 order-sm-1">
            <div className="project__details">
              <h2 className="mb-3">{title}</h2>
              <p className="paragraph--small mb-4">{description}</p>

              <ButtonExternalLink
                href={url}
                target="_blank"
                rel="noopener noreferer"
              >
                Visit site
              </ButtonExternalLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </StyledProjectCard>
)

ProjectCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  image: PropTypes.instanceOf(PropTypes.object).isRequired,
  theme: PropTypes.string.isRequired,
  imageOnRight: PropTypes.bool.isRequired,
}

export default ProjectCard
