import React from "react"

// Libraries
import PropTypes from "prop-types"
import Slick from "react-slick"

// Styles
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Carousel = ({ settings, children }) => (
  <Slick {...settings}>{children}</Slick>
)

Carousel.propTypes = {
  settings: PropTypes.instanceOf(PropTypes.object).isRequired,
  children: PropTypes.elementType.isRequired,
}

export default Carousel
