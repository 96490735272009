import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import ProjectCard from "components/project-card"

const StyledProjects = styled.section`
  padding-bottom: 60px;

  ${breakpoint.medium`
    padding-bottom: 108px;
  `}
`

const Projects = () => {
  const { maintainx, matecaps, purpleBunny } = useStaticQuery(graphql`
    query {
      maintainx: file(
        relativePath: { eq: "home/projects/project-maintainx.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 550, quality: 100)
        }
      }

      matecaps: file(
        relativePath: { eq: "home/projects/project-matecaps.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 550, quality: 100)
        }
      }

      purpleBunny: file(
        relativePath: { eq: "home/projects/project-purple-bunny.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 550, quality: 100)
        }
      }
    }
  `)

  const projects = [
    {
      title: "MaintainX",
      description:
        "A fully-managable, modular site that sources content from multiple CMS's. In a just a heart beat.",
      url: "https://getmaintainx.com",
      image: maintainx,
      theme: "#1887FC",
    },
    {
      title: "MateCaps",
      description:
        "A headless e-commerce with a blazing-fast performance that focus on the user experience.",
      url: "https://matecaps.com",
      image: matecaps,
      theme: "#01664F",
    },
    {
      title: "Purple Bunny",
      description: "Creative design with tons of heavy animations? No problem.",
      url: "https://purplebunny.co",
      image: purpleBunny,
      theme: "#5F05AB",
    },
  ]

  return (
    <StyledProjects>
      <Container>
        <div className="row mb-4 mb-md-5">
          <div className="col-12 col-sm-4">
            <h2 className="mb-2">My latest work</h2>
            <p className="paragraph--small">
              From e-commerces to marketing websites, a collection of past gigs.
            </p>
          </div>
        </div>

        <div className="projects">
          {projects.map((project, index) => (
            <div className="mb-4" key={project.title}>
              <ProjectCard {...project} imageOnRight={index % 2 === 0} />
            </div>
          ))}
        </div>
      </Container>
    </StyledProjects>
  )
}

export default Projects
