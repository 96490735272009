import React from "react"

// Sections
import Hero from "sections/home/hero"
import Skills from "sections/home/skills"
import Projects from "sections/home/projects"

const Home = () => (
  <>
    <Hero />
    <Skills />
    <Projects />
  </>
)

export default Home
